import { Component, Emit, Prop } from 'vue-property-decorator';
import { Button, Multiselect } from '~/components/atoms';
import { ItemData } from '~/components/atoms/select/Select';
import { VueComponent } from '~/utils/vue-component';

import style from './TradeOffersListFilter.scss';
import { ThemeColors } from '~/utils/theme';

const rootClass = 'czt-trade-offers-filter';

export type FilterEmit = FilterData;

export interface Representation {
  id: string;
  name: string;
  languages: ItemData[];
}
interface FilterData {
  languages: string[];
  representations: string[];
}
export interface TradeOffersListFilterOptions {
  representations: Representation[];
}

interface TradeOffersListFilterInterface {
  filterOptions: TradeOffersListFilterOptions;
  loading?: boolean;
  onFilter?: (data: FilterEmit) => void;
}

@Component({
  style,
})
export default class TradeOffersListFilter
  extends VueComponent<TradeOffersListFilterInterface>
  implements TradeOffersListFilterInterface {
  @Prop({ required: true })
  public filterOptions!: TradeOffersListFilterOptions;

  @Prop({ default: false, type: Boolean })
  public loading!: boolean;

  protected get repOptions(): ItemData[] {
    if (this.languages.length > 0) {
      return this.filterOptions.representations
        .filter((repre) => {
          const languages = repre.languages.map((language) => language.value);
          return (
            this.languages.filter((lang) => languages.indexOf(lang) > -1)
              .length > 0
          );
        })
        .map((repre) => {
          return {
            text: repre.name,
            value: repre.id,
          };
        });
    }
    return this.filterOptions.representations.map((repre) => {
      return {
        text: repre.name,
        value: repre.id,
      };
    });
  }

  protected get languageOptions(): ItemData[] {
    let languages: ItemData[][] = [];
    if (this.representations.length > 0) {
      languages = this.filterOptions.representations
        .filter((representation) => {
          return this.representations.indexOf(representation.id) > -1;
        })
        .map((repre) => repre.languages);
    } else {
      languages = this.filterOptions.representations.map((repre) => {
        return repre.languages;
      });
    }
    return Array.prototype.concat.apply([], languages);
  }

  protected representations: string[] = [];
  protected languages: string[] = [];

  public render() {
    if (this.filterOptions.representations.length < 2) {
      return;
    }
    return (
      <v-sheet class={`${rootClass} mb-3`} color={ThemeColors.PRIMARY}>
        <form onSubmit={this.emitFilter}>
          <v-col class='py-0'>
            <v-row>
              <v-col class='py-0'>
                <v-row no-gutters>
                  <v-col cols='12'>
                    <v-row>
                      <v-col cols='12' md='6'>
                        <Multiselect
                          disabled={this.repOptions.length < 2}
                          items={this.repOptions}
                          v-model={this.representations}
                          placeholder={
                            this.repOptions.length < 2
                              ? this.repOptions[0].text
                              : this.$t('app.tradeOffers.country')
                          }
                        />
                      </v-col>
                      <v-col cols='12' md='6'>
                        <Multiselect
                          disabled={this.languageOptions.length < 2}
                          items={this.languageOptions}
                          v-model={this.languages}
                          placeholder={
                            this.languageOptions.length < 2
                              ? this.languageOptions[0].text
                              : this.$t('app.common.language')
                          }
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols='12' md='auto' class='text-center'>
                <Button loading={this.loading} submit>
                  {this.$t('app.search.button')}
                </Button>
              </v-col>
            </v-row>
          </v-col>
        </form>
      </v-sheet>
    );
  }

  @Emit('filter')
  protected emitFilter(e?: Event): FilterEmit {
    if (e) {
      e.preventDefault();
      e.stopImmediatePropagation();
    }
    return {
      representations: this.representations,
      languages: this.languages,
    };
  }
}
